<template>
  <div
    class="pageLayout beauty-scroll"
    :style="!this.isDesign ? 'height: 100vh' : ''"
  >
    <page-header
      :title="activePage.menu.title"
      v-if="activePage.menu.type === 'page' && activePage.menu.uuid !== 'login'"
    ></page-header>

    <!-- 登录界面 -->
    <div class="login-page" v-if="activePage.menu.uuid === 'login'">
      <Login :options="activePage.components[0]" />
    </div>

    <!-- 菜单界面 -->
    <div v-else class="page-body beauty-scroll">
      <div class="page-area" v-if="activePage.menu.type !== 'menu'">
        <ComponentsItem :com="activePage.components[0]" :params="params" />
      </div>
      <div v-else>
        <div
          class="com-row"
          v-for="com in activePage.components"
          :key="com.uuid"
        >
          <ComponentsItem :com="com" :params="params" />
        </div>
      </div>
    </div>

    <!-- 底部导航栏 -->
    <footer-bar
      v-if="activePage.menu.type !== 'page'"
      :menus="appConfig.menus"
      @menuClick="menuClick"
      :activetMenuUuid="activePage.menu.uuid"
    ></footer-bar>
  </div>
</template>
<script>
import Cookie from 'js-cookie'
import { mapMutations, mapGetters } from 'vuex'
import { porConfig } from '@/services/protal'
import { hidePages } from '@/configJson/appComponents'
import FooterBar from './FooterBar.vue'
import ComponentsItem from '../components/ComponentsItem'
import PageHeader from './PageHeader.vue'
import Login from '../entity/Login'
export default {
  components: { FooterBar, ComponentsItem, PageHeader, Login },


  provide() {
    return {
      app: this.appConfig
    }
  },


  props: {
    appConfig: {
      type: Object,
      default: () => ({
        menus: [],
        pageComponents: []
      })
    },
    activePage: {
      type: Object,
      default: () => ({
        menu: {}, // 当前页的菜单信息
        components: [] // 当前页面的所有组件
      })
    },
    isDesign: {
      type: Boolean,
      default: false
    },
    active: {
      type: Object,
      default: () => ({})
    }
  },


  computed: {
    ...mapGetters('datas', ['getUrlHistory'])
  },


  data() {
    return {
      basic: {},
      params: {}
    }
  },


  created() {
    this.initApp()

    this.$bus.$on('app_goTo', (router) => {
      this.skip(router)
    })

    this.$bus.$on('app_back', () => {
      let router = this.getUrlHistory
      this.backHistory()
      if (!router) {
        return
      }
      this.skip(router, false)
    })

    if (!this.isDesign) {
      this.menuClick(this.appConfig.menus[0])
    }
  },
  methods: {
    ...mapMutations('datas', ['addUrlHistory', 'backHistory']),



    
    menuClick(menu) {
      if (this.isDesign) {
        this.$emit('activeMenu', menu)
      } else if (menu) {
        ;[this.activePage.menu, this.activePage.components] = [
          menu,
          this.appConfig.pageComponents[menu.uuid] || [hidePages[menu.uuid]]
        ]
      }
    },




    getMenuByUuid(uuid) {
      let menu = null
      this.appConfig.menus.forEach((m) => {
        if (m.uuid == uuid) {
          menu = m
        }
      })
      if (!menu) {
        let pageMenu = hidePages[uuid]
        menu = {
          ...pageMenu,
          type: 'page',
          uuid: pageMenu.type,
          title: pageMenu.name,
          hide: true
        }
      }
      return menu
    },




    setId() {
      this.activePage.components.forEach((com) => {
        if (!com.uuid) {
          com.uuid = this.nanoid()
        }
      })
    },



    // 初始化
    initApp() {
      const { protal_id } = this.$route.query

      porConfig(protal_id)
        .then((res) => {
          localStorage.setItem('company_uuid', res.data.config.company_uuid)
          
          const { title } = res.data.config
          if(title){
            document.title = title
          }

          Object.assign(this.appConfig, res.data)

          const page = res.data.menus[0]
          
          // 判断是否已经登录
          if (page.uuid === 'login' && Cookie.get('AUTHORIZATION')) {
            const loginConfig = this.appConfig.pageComponents['login']
            // 拿到登录页配置的默认登录跳转位置
            const { url } = loginConfig ? loginConfig[0] : { url: 'login' }
            this.skip({
              url
            })
            return
          }
          this.menuClick(page)
        })
        .catch((err) => {
          this.$router.push('/404')
        })
    },



    // 跳转页面
    skip(router, isReacord = true) {
      if (isReacord) {
        this.addUrlHistory({
          url: this.activePage.menu.uuid,
          params: this.params
        })
      }
      this.params = {}
      let { url, params } = router
      let menu = this.getMenuByUuid(url)
      this.params = params
      this.menuClick(menu)
    }
  },
  watch: {
    activePage: {
      deep: true,
      handler() {}
    },
    appConfig: {
      deep: true,
      handler() {}
    }
  },
  destroyed() {
    this.$bus.$off('app_goTo')
    this.$bus.$off('app_back')
  }
}
</script>
<style lang="less" scoped>
@import './index.less';
.pageLayout {
  height: 100%;
  display: flex;
  flex-direction: column;
  .login-page {
    height: 100%;
  }
  .page-body {
    flex: 1;
    padding: 10px;
    background-color: #f0f2f5;
    overflow-y: auto;
    .page-area {
      height: 100%;
      .componentsItem {
        height: 100%;
      }
    }
    .com-row {
      margin-bottom: 10px;
    }
  }
}
</style>
