<template>
  <div class="show-datas">
    <div class="detail-con" v-for="el in elements" :key="el.options.model">
      <div class="label">{{ el.options.label }}：</div>
      <div class="value">
        <template v-if="el.type === 'checkbox'">
          <span>{{ checkboxData(el.options.options, datas[el.options.model]) }}</span>
        </template>
        <template v-else-if="el.type === 'select' || el.type === 'radio'">
          <span>{{ selectRadio(el.options.options, datas[el.options.model]) }}</span>
        </template>
        <template v-else-if="el.type == 'location'">
          <div style="text-align: left" v-if="datas[el.options.model]">
            <p>
              <span
                class="iconfont icon-dingwei"
                style="color: green; font-size: 14px"
              ></span>
              {{ datas[el.options.model].name }}
            </p>
            <p style="color: #919191">{{ datas[el.options.model].address }}</p>
          </div>
        </template>
        <template v-else-if="el.type == 'selectGroup'">
          {{ datas[el.options.model] }}
        </template>
        <!-- 行政区划 -->
        <template v-else-if="el.type == 'district'">
            {{ Array.isArray(datas[el.options.model]) && datas[el.options.model].length > 1? datas[el.options.model].join(): '' }}
        </template>
        <!-- 签名 -->
        <template v-else-if="el.type == 'sign'">
            <img :src="datas[el.options.model].url" alt="签名图片">
        </template>
        <template v-else-if="el.type == 'alert'">
            <j-warn v-model="datas" :options="el.options"></j-warn>
        </template>
        <template v-else-if="el.type == 'wordTemplate'">
            <div style="color: #399efb;cursor: pointer;" @click="$download(uuid, models.id, el.options.model, datas)">文档下载</div>
        </template>
        <template v-else-if="el.type == 'selectUser'">
          {{ Array.isArray(datas[el.options.model]) && datas[el.options.model].length == 0? '': datas[el.options.model][0].true_name }}
        </template>
        <template v-else-if="el.type == 'rate'">
            {{datas==0?'无评分':''}}
            <a-rate :default-value="datas" disabled :count="datas"/>
        </template>
        <template v-else-if="el.type == 'selectData'">
            <span class="selectDataBtn" type="link">{{datas && datas[2]}}</span>
        </template>
        <template v-else-if="el.type == 'strCheck'">
            图像识别
        </template>
        <template v-else-if="el.type == 'progress'">
            {{ datas[el.options.model] === ''? 0: datas[el.options.model] }}%
        </template>

        <!-- 小数 -->
        <template v-else-if="el.options.tag == 'DoFloat'">
            {{datas[el.options.model] }}
        </template>

        <!-- 图片 -->
        <template v-else-if="el.type == 'image'">
          <div v-for="(item, index) in datas[el.options.model]" :key="index">
            <img :src="item.url" alt="" style="max-width: 100px">
          </div>
        </template>

        <!-- 文件 -->
        <template v-else-if="el.type == 'file'">
          <div v-for="(item, index) in datas[el.options.model]" :key="index">
            <span>{{ item.name }}</span>
          </div>
        </template>

        <template v-else-if="el.type == 'switch'">
            {{ datas ? '是' : '否' }}
        </template>

        <!-- 音频 -->
        <template v-else-if="el.type == 'audio'">
          <audio
            v-if="datas[el.options.model].length > 0"
            :src="datas[el.options.model].length > 0? datas[el.options.model][0].url: ''"
            controls="controls" 
            :autoplay="el.options.autoplay" 
            :loop="el.options.loop"
            :muted="el.options.muted"
            style="max-width: 300px">
              您的浏览器不支持 音频 展示。
          </audio>
          <p v-else>未上传音频文件</p>
        </template>

        <!-- 视频 -->
        <template v-else-if="el.type == 'video'">
          <video 
            v-if="datas[el.options.model].length > 0"
            :src="datas[el.options.model].length > 0? datas[el.options.model][0].url: ''"
            controls="controls" 
            :autoplay="el.options.autoplay" 
            :loop="el.options.loop"
            :muted="el.options.muted"
            style="max-width: 200px">
                您的浏览器不支持 视频 展示。
          </video>
          <p v-else>未上传视频文件</p>
        </template>
        <template v-else-if="el.options.tag == 'DoName'">
          {{ datas[el.options.model] }}
        </template>
        <template v-else-if="el.options.tag == 'DoPhone'">
          {{ datas[el.options.model] }}
        </template>
        <template v-else-if="el.options.tag == 'DoIDNO'">
          {{ datas[el.options.model] }}
        </template>
        <template v-else-if="el.options.tag == 'DoEmail'">
          {{ datas[el.options.model] }}
        </template>
        <template v-else-if="el.type == 'textarea'">
            <div v-if="el.options.isRich" class="ql-container ql-snow" style="border: none;">
                <div class="ql-editor" v-html="datas"></div>
            </div>
            <template v-else>
                {{datas[el.options.model]}}
            </template>
        </template>
        <template v-else-if="el.type == 'table'">
          
        </template>
        <template v-else>
          <span>{{ datas[el.options.model] }}</span>
        </template>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    elements: {
      type: Array,
      default: () => [],
    },
    datas: {
      type: Object,
      default: () => ({}),
    },
  },
  methods: {
    selectRadio(options, val) {
      let result = options.find((op) => op.value == val);
      if (result) {
        return result.label;
      }
      return val;
    },
    checkboxData(options, values) {
      if (!options || !values) {
        return values;
      }
      let temp = options.filter((op) => {
        return values.includes(op.value);
      });
      if (!temp) {
        return values;
      }
      let result = temp.map((op) => op.label);
      if (result.length > 0) {
        return result.join("，");
      }
      return "";
    },
  },
};
</script>
<style scoped lang="less">
.show-datas {
  padding-top: 10px;
  .detail-con {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    .label {
      width: 100px;
      text-align: right;
    }
    .value {
      flex: 1;
    }
  }
}
</style>
