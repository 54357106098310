<template>
  <div class="pageHeader">
    <div class="back" @click="back">
      <a-icon type="left"></a-icon>
    </div>
    <div class="title">
      {{ title }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    showBack: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: '-'
    }
  },
  data() {
    return {}
  },
  created() {},
  methods: {
    back() {
      this.$bus.$emit('app_back')
    }
  }
}
</script>

<style lang="less" scoped>
.pageHeader {
  padding: 8px 5px;
  background-color: #399efb;
  color: #fff;
  font-weight: 500;
  position: relative;
  .back {
    cursor: pointer;
    position: absolute;
    padding: 8px;
    top: 0px;
  }
  .title {
    text-align: center;
  }
}
</style>
