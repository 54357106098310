<template>
  <div class="componentsItem">
    <template v-if="com.type === 'tools'"> 工具1 </template>
    <!-- 数据列表 -->
    <template v-else-if="com.type === 'dataList'">
      <j-data-list :options="com"></j-data-list>
    </template>
    <!-- 滚动信息 -->
    <template v-else-if="com.type === 'broadcast'">
      <j-broadcast :options="com"></j-broadcast>
    </template>
    <template v-else-if="com.type === 'title'">
      <j-line-title :options="com"></j-line-title>
    </template>
    <template v-else-if="com.type === 'plac'">
      <j-plac :options="com"></j-plac>
    </template>
    <template v-else-if="pageComponents[com.type]">
      <component :is="pageComponents[com.type]"></component>
    </template>
  </div>
</template>
<script>
import Login from '../entity/Login'
import Me from '../entity/Me'
import Detail from '../entity/pages/Detail'
import MessageList from '../entity/pages/MessageList'
import TaskList from '../entity/pages/TaskList'
import MsgDetail from '../entity/pages/MsgDetail'
import TaskDetail from '../entity/pages/TaskDetail'
const pageComponents = {
  login: Login,
  me: Me,
  detail: Detail,
  msgList: MessageList,
  taskList: TaskList,
  taskDetail: TaskDetail,
  msgDetail: MsgDetail
}
export default {
  components: { Login, Me, Detail, MessageList },
  props: {
    com: {
      type: Object,
      default: () => ({})
    },
    params: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      pageComponents
    }
  }
}
</script>
