<template>
  <div class="msg-detail">
    <a-card class="card">
      <p class="detail-con">
        <span class="label">消息标题：</span>
        <span class="value">{{ params.msg.title }}</span>
      </p>
      <p class="detail-con">
        <span class="label">消息类型：</span>
        <span class="value">{{ params.msg.msg_type }}</span>
      </p>
      <p class="detail-con">
        <span class="label">发送时间：</span>
        <span class="value">{{ params.msg.create_date }}</span>
      </p>
      <p class="detail-con">
        <span class="label">发送人：</span>
        <span class="value">{{ params.msg.sendname }}</span>
      </p>
    </a-card>
  </div>
</template>
<script>
export default {
  data() {
    return {
      params: {}
    }
  },
  created() {
    this.params = this.$parent.params
  },
  methods: {}
}
</script>
<style lang="less" scoped>
.msg-detail {
  .card {
  }
}
</style>
