<template>
  <div class="message-list">
    <div class="header">
      <div class="status">
        <div
          v-for="st in status"
          :key="st.value"
          :class="['status-item', { active: params.t === st.value }]"
          @click="statusChange(st.value)"
        >
          <span>
            {{ st.label }}
          </span>
        </div>
      </div>
    </div>
    <div class="data-list beauty-scroll">
      <div class="isNull" v-if="messageList.length === 0">暂无数据</div>
      <div
        class="data-item"
        v-for="(msg, index) in messageList"
        :key="msg.id"
        @click="toMsgDetail(msg)"
      >
        <div class="no">
          <h3>{{ index + 1 }}</h3>
        </div>
        <div class="info">
          <div class="top info-box">
            <div class="title">
              <j-text>{{ msg.title }}</j-text>
            </div>
            <a-icon type="right"></a-icon>
          </div>
          <div class="footer info-box">
            <div class="date">
              <a-icon type="calendar" />
              {{ msg.create_date }}
            </div>
            <div class="user">
              <a-icon type="user"></a-icon>
              {{ msg.sendname }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { getMessageList } from '@/services/protal'
const status = [
  {
    value: '',
    label: '全部'
  },
  {
    value: 0,
    label: '未读'
  },
  {
    value: 1,
    label: '已读'
  },
  {
    value: 2,
    label: '未处理'
  },
  {
    value: 3,
    label: '已处理'
  }
]
export default {
  data() {
    return {
      status,
      params: {
        t: ''
      },
      messageList: []
    }
  },
  created() {
    console.log(this.params)
    this.getMessageList()
  },
  methods: {
    statusChange(value) {
      this.params.t = value
      this.getMessageList()
    },
    getMessageList() {
      getMessageList(this.params).then((res) => {
        let { data } = res.data.data
        this.messageList = data
      })
    },
    toMsgDetail(msg) {
      this.$bus.$emit('app_goTo', {
        url: 'msgDetail',
        params: {
          msg
        }
      })
    }
  }
}
</script>
<style lang="less" scoped>
@import './css/public';
.message-list {
  height: 100%;
  display: flex;
  flex-direction: column;
  .data-list {
    flex: 1;
    flex-shrink: 0;
    overflow-y: auto;
  }
}
</style>
