<template>
  <div class="taks-detail">
    <a-card class="card">
      <p class="detail-con">
        <span class="label">任务标题：</span>
        <span class="value">{{ params.task.title }}</span>
      </p>
      <p class="detail-con">
        <span class="label">开始时间：</span>
        <span class="value">{{ params.task.start_date }}</span>
      </p>
      <p class="detail-con">
        <span class="label">结束时间：</span>
        <span class="value">{{ params.task.end_date }}</span>
      </p>
      <p class="detail-con">
        <span class="label">执行人：</span>
        <span class="value">{{ params.task.userList }}</span>
      </p>
      <p class="detail-con">
        <span class="label">发起人：</span>
        <span class="value">{{ params.task.create_user }}</span>
      </p>
    </a-card>
  </div>
</template>
<script>
export default {
  data() {
    return {
      params: {}
    }
  },
  created() {
    this.params = this.$parent.params
  },
  methods: {}
}
</script>
<style lang="less" scoped>
.taks-detail {
  .card {
  }
}
</style>
