<template>
  <div class="footerBar">
    <div class="bar-items">
      <div
        class="bar-item"
        :class="{ active: activetMenuUuid == menu.uuid }"
        v-for="menu in filterMenus"
        :key="menu.uuid"
        @click="menuClick(menu)"
      >
        <a-icon :type="menu.icon" /> {{ menu.title }}
      </div>
    </div>
  </div>
</template>
<script>
import { mapMutations } from 'vuex'
export default {
  props: {
    menus: {
      type: Array,
      default: () => []
    },
    activetMenuUuid: {
      type: String,
      default: ''
    }
  },
  computed: {
    filterMenus() {
      let menus = this.menus.filter((menu) => menu.type !== 'page')
      return menus
    }
  },
  data() {
    return {
      appId: 'xxxxa'
    }
  },
  methods: {
    ...mapMutations('datas', ['addUrlHistory']),
    menuClick(menu) {
      this.$emit('menuClick', menu)
    }
  }
}
</script>

<style lang="less" scoped>
.footerBar {
  .bar-items {
    border-top: 1px solid rgba(121, 121, 121, 0.1);
    border-bottom: 1px solid rgba(121, 121, 121, 0.1);
    display: flex;
    .bar-item {
      flex: 1;
      text-align: center;
      padding: 1em 0px;
      // border-right: 1px solid rgba(0, 0, 0, 0.1);
      cursor: pointer;
      font-weight: 600;
      &:last-child {
        border-right: none;
      }
      &.active {
        // background-color: #399efb;
        color: #399efb;
      }
    }
  }
}
</style>
