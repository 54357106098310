<template>
  <div class="detail">
    <div v-if="!isShow" class="null">参数有误</div>
    <div v-else class="content" :class="{ 'hide-tabs': pageView.length <= 1 }">
      <a-card class="card">
        <a-tabs default-active-key="1">
          <a-tab-pane
            v-for="view in pageView"
            :key="view.key"
            :tab="view.label"
          >
            <ShowDataItem :elements="view.elements" :datas="dataModels" />
          </a-tab-pane>
        </a-tabs>
      </a-card>
    </div>
  </div>
</template>
<script>
import { formData, getForm } from '@/services/protal'
import ShowDataItem from '../../components/ShowDataItem.vue'
export default {
  data() {
    return {
      dataModels: {},
      formInfo: {},
      form_uuid: '',
      data_id: '',
      isShow: true,
      pageView: [
        {
          label: '首页',
          key: 'index',
          elements: []
        }
      ]
    }
  },
  components: { ShowDataItem },
  created() {
    let { form_uuid, data_id } = this.$parent.params
    if (!form_uuid || !data_id) {
      this.isShow = false
      return
    } else {
      this.isShow = true
    }
    this.form_uuid = form_uuid
    this.data_id = data_id
    this.init()
  },
  methods: {
    init() {
      getForm({ uuid: this.form_uuid }).then((res) => {
        let { config, elements } = res.data.data
        this.formInfo = {
          config,
          elements
        }
        this.initView(elements)
        formData({
          table_uuid: this.form_uuid,
          data_id: this.data_id
        }).then((res) => {
          let { data } = res.data.data
          this.dataModels = data
        })
      })
    },
    initView(elements) {
      elements.forEach((el) => {
        if (el.type !== 'panel') {
          this.pageView[0].elements.push(el)
        } else {
          this.pageView.push({
            label: el.options.label,
            key: el.options.model,
            elements: el.elements
          })
        }
      })
    }
  }
}
</script>
<style lang="less" scoped>
.detail {
  .null {
    text-align: center;
  }
  .content {
    /deep/ .card {
      .ant-card-body {
        padding-top: 0px;
      }
    }
    &.hide-tabs {
      /deep/ .ant-tabs-bar {
        display: none;
      }
      .card {
        .ant-card-body {
          padding-top: 0px;
        }
      }
    }
  }
}
</style>
