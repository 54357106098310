const components = [
  {
    name: '广播消息',
    type: 'broadcast',
    icon: 'icon-laba_',
    text: '这是一条测试消息内容，当内容超出显示区域则会滚动' // 消息内容
  },
  {
    name: '数据列表',
    type: 'dataList',
    icon: 'icon-shujuwajue',
    appUuid: '',
    formUuid: '',
    viewId: '',
    dataSize: 10 // 显示数据量
  },
  {
    name: '标题',
    type: 'title',
    icon: 'icon-title',
    label: '标题' // 消息内容
  },
  {
    name: '占位',
    type: 'plac',
    icon: 'icon-fenlei',
    content: ''
  }
]
const pages = {
  login: {
    name: '登录',
    type: 'login',
    title: '都易云', // 网站名称
    memo: '无代码开发平台', // 备注说明
    url: '', // 跳转到的菜单uuid
    welcome: '', // 欢迎文字
    welcome_image: '', // 欢迎图片
    login_bgcolor: '#399efb', // 登录页背景颜色
    login_image: '', // 登录页背景图片
    login_button_bgcolor: '#399efb', // 登录按钮背景颜色
    login_text_color: '#ffffff' // 登录按钮文字颜色
  },
  me: {
    name: '我的',
    type: 'me'
  }
}
// 应用中默认需要用的页面
const hidePages = {
  detail: {
    name: '详情页',
    type: 'detail'
  },
  msgList: {
    name: '消息列表',
    type: 'msgList'
  },
  taskList: {
    name: '任务列表',
    type: 'taskList'
  },
  taskDetail: {
    name: '任务详情',
    type: 'taskDetail'
  },
  msgDetail: {
    name: '消息详情',
    type: 'msgDetail'
  }
}
export { components, pages, hidePages }
