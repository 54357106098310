<template>
  <div class="app-login" :style="{ 'background-color': options.login_bgcolor }">

    <!-- 
      appConfig.member === 0时：账号密码登录
      appConfig.member === 1时：会员登录 
    -->
    <div class="login-area">
      <div class="area-head">
        <h2 class="title">
          {{ options.title }}
        </h2>
        <p class="desc">
          {{ options.memo }}
        </p>
      </div>
      <div class="area-form">
        <a-form-model :model="login">
          <a-form-model-item label="账号">
            <a-input v-model="login.name" placeholder="请输入账号" />
          </a-form-model-item>
          <a-form-model-item label="密码">
            <a-input
              type="password"
              v-model="login.password"
              placeholder="请输入密码"
            />
          </a-form-model-item>
          <div
            class="button"
            :style="{
              'background-color': options.login_button_bgcolor,
              color: options.login_text_color
            }"
            @click="toLogin"
          >
            {{ appConfig.member == 0 ? '账号登录': '会员登录'}}
          </div>
        </a-form-model>
      </div>
    </div>
  </div>
</template>
<script>
import Cookie from 'js-cookie'
import { login } from '@/services/protal'
export default {
  props: {
    options: {
      type: Object,
      default: () => ({
        title: 'xxx', // 网站名称
        memo: 'xxxxxxx', // 备注说明
        welcome: '', // 欢迎文字
        welcome_image: '', // 欢迎图片
        login_bgcolor: '#399efb', // 登录页背景颜色
        login_image: '', // 登录页背景图片
        login_button_bgcolor: '#399efb', // 登录按钮背景颜色
        login_text_color: '#ffffff', // 登录按钮文字颜色,
        login_sms: 0,
        reg_member: 0
      })
    }
  },
  inject: ['app'],
  data() {
    return {
      login: {
        name: '',
        password: ''
      },
      loginMode: 'account',
      appConfig: {},
    }
  },
  created() {
    this.appConfig = this.app.config
  },
  methods: {
    // 登录
    toLogin() {
      const { member, domain, state } = this.appConfig
      if (state == 0) {
        this.$message.error('该门户未启用，登录失败')
        return
      }
      login(this.login, member, { domain }).then((res) => {
        const { code, userinfo, token } = res.data.data
        if (code === 0) {
          this.$message.success('登录成功！')
          Cookie.set('AUTHORIZATION', token)
          localStorage.setItem('userInfo', JSON.stringify(userinfo))
          this.$bus.$emit('app_goTo', {
            url: this.options.url,
            params: {}
          })
        } else {
          this.$message.error('账号或密码错误！')
        }
      })
    }
  }
}
</script>
<style scoped lang="less">
p {
  padding: 0;
  margin: 0;
}
/deep/ .ant-form-item {
  margin-bottom: 0;
}
.app-login {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  background-color: #399efb;
  .login-area {
    width: 90%;
    max-width: 380px;
    height: 380px;
    padding: 15px;
    border-radius: 10px;
    background-color: #fff;
    .area-head {
      text-align: center;
      .title {
        font-weight: bold;
        margin: 0;
      }
    }
    .area-form {
      .button {
        width: 100%;
        padding: 5px 0;
        background-color: #399efb;
        text-align: center;
        border-radius: 5px;
        color: #fff;
        margin: 5px 0;
        cursor: pointer;
        transition: opacity 0.3s;
        &:hover {
          opacity: 0.9;
        }
      }
      .footer {
        display: flex;
        justify-content: flex-end;
      }
    }
  }
}
</style>
