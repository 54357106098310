<template>
  <div class="app-me">
    <div class="user-info card" v-if="userInfo">
      <!-- <img
        class="avatar"
        src="https://img0.baidu.com/it/u=2435935024,713259077&fm=253&fmt=auto&app=138&f=JPEG?w=500&h=500"
      /> -->
      <j-avatar
        :size="50"
        :name="userInfo.true_name"
        :url="userInfo.avatar"
      ></j-avatar>
      <div class="info-center">
        <p class="username">{{ userInfo.true_name }}</p>
      </div>
    </div>
    <div class="list card">
      <div class="list-item" @click="toMessageList()">
        <div class="info">
          <a-icon type="bell" />
          我的消息
        </div>
        <a-icon type="right"></a-icon>
      </div>
      <div class="list-item" @click="toTaskList()">
        <div class="info">
          <a-icon type="copy" />
          我的任务
        </div>
        <a-icon type="right"></a-icon>
      </div>
    </div>
    <div class="list card">
      <div class="list-item" @click="logout()">
        <div class="info">
          <a-icon type="poweroff" />
          退出登录
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      userInfo: {}
    }
  },
  created() {
    let userInfo = localStorage.getItem('userInfo')
    userInfo = userInfo ? JSON.parse(userInfo) : this.$goToLogin()
    this.userInfo = userInfo
  },
  methods: {
    logout() {
      this.$goToLogin()
      // this.$bus.$emit('app_goTo', {
      //   url: 'login'
      // })
    },
    toMessageList() {
      this.$bus.$emit('app_goTo', {
        url: 'msgList'
      })
    },
    toTaskList() {
      this.$bus.$emit('app_goTo', {
        url: 'taskList'
      })
    }
  }
}
</script>
<style lang="less" scoped>
p {
  padding: 0;
  margin: 0;
}
.app-me {
  .card {
    background-color: #fff;
    padding: 10px;
    border-radius: 5px;
    margin-bottom: 10px;
  }
  .user-info {
    display: flex;
    flex-direction: column;
    align-items: center;
    .avatar {
      width: 60px;
      height: 60px;
      margin-bottom: 10px;
      border-radius: 50%;
    }
    .info-center {
      padding: 0 10px;
      font-weight: bold;
      text-align: center;
    }
  }
  .list {
    padding: 0;
    .list-item {
      display: flex;
      align-items: center;
      padding: 10px;
      border-bottom: 1px solid rgba(121, 121, 121, 0.1);
      .info {
        flex: 1;
      }
    }
  }
}
</style>
